import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SubjectOutlined from "@material-ui/icons/SubjectOutlined";
import AddCircleOutlineOutlined from "@material-ui/icons/AddCircleOutlineOutlined";

const drawerWidth = 275;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    },
    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, .10)"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  containerVertical: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 20px)"
  },
  containerVerticalContent: {
    flexGrow: 1
  },
  badge: {
    display: "inline-block",
    backgroundColor: "rgba(0,0,0,.05)",
    color: "rgba(0,0,0,.8)",
    borderRadius: "0.625rem",
    fontSize: "80%",
    padding: "3px 10px",
    margin: "0 3px 4px 0"
  },
  brand: {
    flexGrow: 1
  }
}));

function Layout(props) {
  const { window, children } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const menuItems = [
    {
      text: "My Notes",
      icon: <SubjectOutlined color="secondary" />,
      path: "/"
    },
    {
      text: "Create Note",
      icon: <AddCircleOutlineOutlined color="secondary" />,
      path: "/create"
    }
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div className={classes.containerVertical}>
      <div className={classes.containerVerticalContent}>
        <div className={classes.toolbar}>
          <Typography variant="subtitle1" color="textSecondary" style={{ padding: "1rem" }}>
            Version 1.0.0
          </Typography>
        </div>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => {
                setMobileOpen(false);
                history.push(item.path);
              }}
              className={
                location.pathname === item.path ? classes.active : null
              }
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ marginBottom: "40px" }}>
        <Divider />
        <div style={{ padding: "0 1rem 1rem 1rem", color: "rgba(0,0,0,.6)", fontWeight: 300 }}>
          <p style={{ marginBottom: "30px" }}>
            Created by Youcef Developer &copy; 2021
          </p>
          <p>Technologies</p>
          <span className={classes.badge}>Java</span>
          <span className={classes.badge}>Lombok</span>
          <span className={classes.badge}>Spring Boot</span>
          <span className={classes.badge}>Maven</span>
          <span className={classes.badge}>SQLite</span>
          <span className={classes.badge}>REST API</span>
          <span className={classes.badge}>React</span>
          <span className={classes.badge}>Material UI</span>
        </div>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className={classes.appBar}
        color="secondary"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.brand}>
            Youdev Notes
          </Typography>
          {/* <Typography style={{ marginRight: ".8rem" }}>
            Login
          </Typography>
          <Typography>Register</Typography> */}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default Layout;
