import React, { useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Masonry from "react-masonry-css";
import NoteCard from "../components/NoteCard";
import NoteService from "../services/NoteService";

const useStyles = makeStyles(() => ({
  containerVertical: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 240px)"
  },
  containerVerticalContent: {
    flexGrow: 1
  }
}));

export default function Notes() {
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await NoteService.getNotes();
        setNotes(res.data);
      } catch (err) {
        setError(`${err.message}, please try again later!`);
        console.log("Something went wrong");
      }
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleDelete = (id) => {
    const deleteData = async () => {
      try {
        const res = await NoteService.deleteNote(id);
        if (res.data.deleted === true) {
          const newNotes = notes.filter((note) => note.id !== parseInt(id));
          setNotes(newNotes);
        }
      } catch (err) {
        console.log("Something went wrong");
      }
    };

    deleteData();
  };

  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1
  };

  // if (!notes.length) {
  //   return (
  //     <Container>
  //       <Typography color="textSecondary" style={{ marginBottom: "20px" }}>
  //         There are no notes, you can click bellow button to start adding your first one.
  //       </Typography>
  //       <Button
  //         type="button"
  //         size="large"
  //         color="secondary"
  //         variant="contained"
  //         disableElevation
  //         onClick={() => history.push("/create")}
  //       >
  //         Create a note
  //       </Button>
  //     </Container>
  //   );
  // }

  if (loading)
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="bottom" pt={6}>
          <CircularProgress color="secondary" size={68} />
        </Box>
      </Container>
    );

  if (error)
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="bottom" pt={6}>
          <h2 style={{ fontWeight: "300" }}>{error}</h2>
        </Box>
      </Container>
    );

  return (
    <Container>
      <div className={classes.containerVertical}>
        <div className={classes.containerVerticalContent}>
          <Masonry
            breakpointCols={breakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {notes.map((note) => (
              <div key={note.id}>
                <NoteCard note={note} handleDelete={handleDelete} />
              </div>
            ))}
          </Masonry>
        </div>
      </div>
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "40px" }}
      >
        <Pagination
          color="secondary"
          count={10}
          size="large"
          siblingCount={1}
        />
      </Box> */}
    </Container>
  );
}
