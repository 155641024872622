import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router-dom";
import NoteService from "../services/NoteService";

const useStyles = makeStyles({
  field: {
    marginTop: 10,
    marginBottom: 20,
    display: "block"
  },
  card: {
    borderRadius: 10,
    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, .10)",
    padding: 8
  }
});

export default function Create() {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [detailsError, setDetailsError] = useState(false);
  const [category, setCategory] = useState("0");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTitleError(false);
    setDetailsError(false);

    if (title === "") {
      setTitleError(true);
    }
    if (details === "") {
      setDetailsError(true);
    }
    if (title && details) {
      try {
        const note = { title, details, category };
        await NoteService.createNote(note);
        history.push("/");
      } catch (err) {
        console.log("Something went wrong!");
      }
    }
  };

  const clearForm = () => {
    setTitleError(false);
    setDetailsError(false);
    setTitle("");
    setDetails("");
    setCategory("0");
  };

  return (
    <Container size="sm">
      <Typography
        variant="h5"
        color="textSecondary"
        component="h2"
        gutterBottom
        style={{ marginBottom: "1rem", fontWeight: 400 }}
      >
        Create a New Note
      </Typography>
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={classes.field}
              label="Note Title"
              variant="outlined"
              color="secondary"
              fullWidth
              required
              error={titleError}
            />
            <TextField
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              className={classes.field}
              label="Details"
              variant="outlined"
              color="secondary"
              multiline
              rows={4}
              fullWidth
              required
              error={detailsError}
            />
            <FormControl className={classes.field}>
              <FormLabel>Note Category</FormLabel>
              <RadioGroup
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <FormControlLabel value="0" control={<Radio />} label="Money" />
                <FormControlLabel value="1" control={<Radio />} label="Todos" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="Reminders"
                />
                <FormControlLabel value="3" control={<Radio />} label="Work" />
              </RadioGroup>
            </FormControl>
            <Button
              type="submit"
              size="large"
              color="secondary"
              variant="contained"
              disableElevation
              style={{ marginRight: "5px" }}
            >
              Create
            </Button>
            <Button
              type="button"
              size="large"
              color="default"
              style={{ fontWeight: 400 }}
              variant="contained"
              disableElevation
              onClick={clearForm}
            >
              Clear
            </Button>
          </form>
        </CardContent>
      </Card>
    </Container>
  );
}
