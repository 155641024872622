import React from "react";
import { useHistory } from "react-router-dom";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { yellow, green, pink, blue } from "@material-ui/core/colors";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ShareIcon from "@material-ui/icons/Share";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: (note) => {
      if (note.categoryLabel === "work") {
        return yellow[700];
      }
      if (note.categoryLabel === "money") {
        return green[500];
      }
      if (note.categoryLabel === "todos") {
        return pink[500];
      }
      return blue[500];
    }
  },
  card: {
    borderRadius: 10,
    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, .10)",
    padding: 8
  }
});

export default function NoteCard({ note, handleDelete, details, share }) {
  const classes = useStyles(note);
  const history = useHistory();

  return (
    <div>
      <Card variant="outlined" className={classes.card}>
        <CardHeader
          avatar={
            <Avatar className={classes.avatar}>
              {note.categoryLabel[0].toUpperCase()}
            </Avatar>
          }
          action={
            <>
              <IconButton onClick={() => history.push(`/update/${note.id}`)}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  window.confirm(
                    "Are you sure you wish to delete this note?"
                  ) && handleDelete(note.id)
                }
              >
                <DeleteOutlined />
              </IconButton>
            </>
          }
          title={<span style={{fontSize: '15px', fontWeight: 500}}>{note.title}</span>}
          subheader={<span style={{fontWeight: 300}}>{note.categoryLabel}</span>}
        />
        <CardContent>
          <Typography variant="body2" style={{fontWeight: 400}} color="textSecondary">
            {note.details}
          </Typography>
        </CardContent>
        {(details || share) && (
          <CardActions>
            {details && (
              <Button
                size="small"
                color="primary"
                style={{fontWeight: 400}} 
                endIcon={<ChevronRightIcon />}
                onClick={() => history.push(`/notes/${note.id}`)}
              >
                View Details
              </Button>
            )}
            {share && (
              <Button
                size="small"
                color="primary"
                style={{fontWeight: 400}} 
                onClick={() => console.log("sharing...")}
                startIcon={<ShareIcon />}
              >
                Share
              </Button>
            )}
          </CardActions>
        )}
      </Card>
    </div>
  );
}

NoteCard.defaultProps = {
  details: true,
  share: false
};
