import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import NoteService from "../services/NoteService";
import NoteCard from "../components/NoteCard";

export default function NoteDetails() {
  const [note, setNote] = useState(null);
  const history = useHistory();
  const params = useParams();
  const noteId = params.id;

  useEffect(() => {
    const fetchData = async () => {
      const res = await NoteService.getNote(noteId);
      const note = res.data;

      setNote(note);
      console.log("note:", note);
    };

    fetchData();
  }, [noteId]);

  const handleDelete = (id) => {
    const deleteData = async () => {
      try {
        const res = await NoteService.deleteNote(id);
        if (res.data.deleted === true) {
          history.push("/");
        }
      } catch (err) {
        console.log("Something went wrong");
      }
    };

    deleteData();
  };

  if (!note) {
    return (
      <Container align="center">
        <CircularProgress color="secondary" />
      </Container>
    );
  }

  return (
    <Container>
      <NoteCard
        note={note}
        handleDelete={handleDelete}
        details={false}
        share={true}
      ></NoteCard>
    </Container>
  );
}
