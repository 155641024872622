import axios from "axios";

const NOTE_API_BASE_URL = process.env.REACT_APP_NOTE_API_BASE_URL;

class NoteService {
  getNotes() {
    return axios.get(NOTE_API_BASE_URL);
  }

  getNote(id) {
    return axios.get(`${NOTE_API_BASE_URL}/${id}`);
  }

  createNote(note) {
    return axios.post(NOTE_API_BASE_URL, note);
  }

  updateNote(id, note) {
    return axios.put(`${NOTE_API_BASE_URL}/${id}`, note);
  }

  deleteNote(id) {
    return axios.delete(`${NOTE_API_BASE_URL}/${id}`);
  }
}

export default new NoteService();
