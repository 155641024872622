import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Layout from "./components/Layout";
import { grey } from "@material-ui/core/colors";

import Notes from "./pages/Notes";
import Create from "./pages/Create";
import Update from "./pages/Update";
import NoteDetails from "./pages/NoteDetails";

const theme = createMuiTheme({
  palette: {
    primary: grey,
    secondary: {
      main: "#0e9aa7"
    }
  },
  typography: {
    fontFamily: "Noto Sans TC",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Notes />
            </Route>
            <Route path="/create">
              <Create />
            </Route>
            <Route path="/update/:id">
              <Update />
            </Route>
            <Route path="/notes/:id">
              <NoteDetails />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;